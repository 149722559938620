import React from "react";
interface logoutProp {
  onCancel: () => void;
  onAccepte: () => void;
  title: string;
  subTitle: string;
  icon?: JSX.Element;
  children?: JSX.Element | JSX.Element[];
}
export default function LogoutPopUp(props: logoutProp) {
  return (
    <div className="fixed z-10 top-0 left-0 right-0 bottom-0 w-full h-screen bg-black/10 bg-opacity-50 justify-center items-center flex flex-col">
      <div className=" flex flex-col justify-start mx-auto  w-[400px]  bg-white rounded-lg  py-6 gap-5 shadow-xl">
        <div className=" px-6 flex flex-col justify-start h-auto items-start w-full gap-5">
          <div className="rounded-full bg-[#FEE4E2] w-12 h-12 justify-center flex items-center border-[#FEF3F2] border-8 mb-2 ">
            {props.icon}
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-lg font-tajawalMedium text-gray-900">
              {props.title}
            </p>
            <p className="text-base font-tajawalMedium text-gray-500">
              {props.subTitle}
            </p>
          </div>
        </div>

        <div>{props.children}</div>

        <div className="flex justify-between px-6 gap-3">
          <button
            className="w-44 h-12 shadow-sm rounded-lg border border-gray-300"
            onClick={props.onCancel}
          >
            cancel
          </button>
          <button
            className="w-44 h-12 bg-[#D92D20] text-white shadow-sm rounded-lg "
            onClick={props.onAccepte}
          >
            Log out
          </button>
        </div>
      </div>
    </div>
  );
}
