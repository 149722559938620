import React from "react";
import { Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { appRoutesObj } from "./app.paths";
import MainLayout from "./components/layout/layout";
import Loading from "./components/user/loading";
import { AuthProvider } from "./containers/common/user-context";
import DashboardPage from "./pages/user/dashboard-page/dashboard-page";
const ExamplePage = React.lazy(() => import("./pages/user/example-page"));
const NotFoundPage = React.lazy(() => import("./pages/404"));
const Login = React.lazy(() => import("./pages/user/login-page/login-page"));
// const Visit = React.lazy(() => import("./pages/user/visit-page/visit-page"));
const Patient = React.lazy(
  () => import("./pages/user/patient-page/patient-page"),
);
const Examination = React.lazy(
  () => import("./pages/user/examination-page/examination-page"),
);

const withSuspense = (WrappedComponent: JSX.Element) => {
  return (
    <Suspense
      fallback={
        <div className="pt-10 text-primary-200">
          <Loading />
        </div>
      }
    >
      {WrappedComponent}
    </Suspense>
  );
};

export function AppRouting() {
  return (
    <div>
      <Suspense
        fallback={
          <div className="pt-10 text-primary-200">
            <Loading />
          </div>
        }
      >
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              <Route
                key="login"
                path={appRoutesObj.getLoginPage()}
                element={withSuspense(<Login />)}
              />
              <Route
                key="homePage"
                path={appRoutesObj.getBasePath()}
                element={withSuspense(<MainLayout />)}
              >
                <Route
                  key="homepage"
                  path={appRoutesObj.getBasePath()}
                  element={withSuspense(<DashboardPage />)}
                />
                <Route
                  key="patient"
                  path={appRoutesObj.getPatientPage()}
                  element={withSuspense(<Patient />)}
                />
                <Route
                  key="examination"
                  path={appRoutesObj.getExaminationPage()}
                  element={withSuspense(<Examination />)}
                />

                <Route
                  key="examplePage"
                  path={appRoutesObj.getExamplePagePath()}
                  element={withSuspense(<ExamplePage />)}
                />

                <Route
                  key="notDefined"
                  path="*"
                  element={withSuspense(<NotFoundPage />)}
                />
                <Route
                  key={"default"}
                  path={""}
                  element={<Navigate to={appRoutesObj.getVisitPage()} />}
                />
              </Route>
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}
