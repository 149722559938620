import { Navigate } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";

interface AuthAccessWrapperContainerProps {
  children: JSX.Element;
}

const AuthAccessWrapperContainer = ({
  children,
}: AuthAccessWrapperContainerProps) => {
  const token = localStorage.getItem("token") as string;

  if (!token) {
    return <Navigate to={appRoutesObj.getLoginPage()} />;
  }

  return <>{children}</>;
};

export default AuthAccessWrapperContainer;
