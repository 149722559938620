import React from "react";
import Lottie from "react-lottie";

import animationData from "./animation/Animation - 1709188266999.json";

export default function Loading() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="justify-center items-center w-full h-full">
      <div className="mx-auto ">
        <Lottie
          options={defaultOptions}
          height={200}
          width={200}
          isClickToPauseDisabled
        />
      </div>
    </div>
  );
}
