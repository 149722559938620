import { toast } from "react-toastify";

import { ErrorDetailType, ErrorType } from "../types/error.type";

/**
 *
 * @param error
 * @description handles all requests catch errors
 */
export const handleRequestError = (error: unknown) => {
  toast.error(getErrorMessage((error as ErrorType)?.response?.data?.detail));
};

/**
 * @param error
 * @description
 * @returns error string
 */
export const getErrorMessage = (error: string | ErrorDetailType) => {
  if (!error) {
    return "حدث خطأ، الرجاء المحاولة مرة أخرى";
  }

  if (typeof error === "string") {
    return error;
  }

  if ((error as ErrorDetailType)?.error_code) {
    return (error as ErrorDetailType)?.error_message;
  }

  return "حدث خطأ، الرجاء المحاولة مرة أخرى";
};
