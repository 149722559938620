import React, { useState } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlineDashboard } from "react-icons/md";
import { RiUserLine } from "react-icons/ri";
import { TbLogout } from "react-icons/tb";
import { TbEyeTable } from "react-icons/tb";
import { NavLink, useNavigate } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import { removeLocalStorage } from "src/core/stores/localStorage";

import LogoutPopUp from "../user/logout-pop-up";

const SideBar = () => {
  const Navigate = useNavigate();
  const [show, setShow] = useState(false);
  const Menus = [
    { title: "Dashboard", icon: <MdOutlineDashboard />, url: "/" },
    {
      title: "Create Patient",
      icon: <RiUserLine />,
      url: appRoutesObj.getPatientPage(),
    },
    {
      title: "Examination ",
      icon: <TbEyeTable />,
      url: appRoutesObj.getExaminationPage(),
    },
  ];

  return (
    <div className="h-screen sticky top-0 left-0 z-10">
      <div className="flex flex-col justify-between p-5 relative duration-300 bg-white font-tajawalRegular h-full">
        <div>
          <div className="flex justify-center items-center mb-5">
            <img className="w-28" src={"/assets/images/SDM_Logo_RGB 1.png"} />
          </div>
          <div>
            <div className="w-full flex flex-col gap-3">
              {Menus.map((Menu, index) => (
                <NavLink
                  key={index}
                  to={Menu.url}
                  className={({ isActive }) => `
              flex gap-2 px-9 py-2 items-center rounded-lg
              ${isActive ? "bg-primaryBlue text-white" : "text-gray500"}
         `}
                >
                  <span>{Menu.icon}</span>
                  <span>{Menu.title}</span>
                </NavLink>
              ))}
            </div>
          </div>
        </div>
        <div className={`w-full flex flex-col gap-3`}>
          <button className="flex gap-2 px-9 py-2 items-center rounded-lg">
            <span>
              <IoSettingsOutline />
            </span>
            Settings
          </button>

          <button
            className="flex gap-2 px-9 py-2 items-center text-red-600 rounded-lg transition-all hover:bg-red-700 hover:text-white"
            onClick={() => setShow(!show)}
          >
            <span>
              <TbLogout />
            </span>
            Log out
          </button>
        </div>
      </div>
      {show && (
        <LogoutPopUp
          title="Log out"
          onCancel={() => setShow(false)}
          icon={<FiAlertCircle color="#D92D20" size={24} />}
          subTitle={"Are you sure you want to log out ?"}
          onAccepte={() => {
            removeLocalStorage("token");
            removeLocalStorage("email");
            Navigate(appRoutesObj.getLoginPage());
          }}
        >
          <div></div>
        </LogoutPopUp>
      )}
    </div>
  );
};
export default SideBar;
