import { Outlet } from "react-router-dom";
import AuthAccessWrapperContainer from "src/containers/common/auth-access-wrapper";
import HeaderContainer from "src/containers/common/header-container";

import SideBar from "../common/side-bar";

const MainLayout = () => {
  return (
    <AuthAccessWrapperContainer>
      <div className="relative grid grid-cols-[276px,1fr] bg-gray-50 w-full">
        <div className="sticky h-screen top-0 left-0 z-[1]">
          <SideBar />
        </div>
        <div className="px-10 flex-grow">
          <div>
            <HeaderContainer />
          </div>
          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </AuthAccessWrapperContainer>
  );
};

export default MainLayout;
