import { baseURL, makeRequest } from "src/core/data-access/http.service";
import { HttpMethods } from "src/core/enums/httpMethods.enum";
import {
  RegisterCenterModel,
  UpdateCenterModel,
} from "src/core/types/center.type";

export const postRegisterCenterService = async (data: RegisterCenterModel) => {
  return await makeRequest({
    url: `${baseURL}/center`,
    method: HttpMethods.POST,
    data,
  });
};

export const updateCenterService = (
  data: UpdateCenterModel,
  centerId: string,
) => {
  return makeRequest({
    url: `${baseURL}/center/${centerId}`,
    method: HttpMethods.PUT,
    data,
  });
};

export const getCenterInfo = (token: string, centerId: string) => {
  return makeRequest({
    url: `${baseURL}/center/${centerId}`,
    method: HttpMethods.GET,
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};
export const getAllCentersInfo = (token: string, centerId: string) => {
  return makeRequest({
    url: `${baseURL}/center/${centerId}/`,
    method: HttpMethods.GET,
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};
export const getAllCenterByIdService = (searchText: string) => {
  return makeRequest({
    url: `${baseURL}/center`,
    method: HttpMethods.GET,
    params: {
      q: searchText,
    },
  });
};

export const getCenterPatientsService = (token: string) => {
  return makeRequest({
    url: `${baseURL}/centres-patients`,
    method: HttpMethods.GET,
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};
