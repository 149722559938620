import { Location } from "history";
import { useLocation } from "react-router-dom";

import TitleComponent from "./title-component";
export default function BreadcrumbComponent() {
  const location = useLocation();

  const handelRoute = (location: Location) => {
    switch (location.pathname) {
      case "/visit":
        return "Create Visit";
      case "/Patient":
        return "Create Patient";
      case "/examination":
        return "Examination";
      case "/":
        return "Dashboard";
    }
  };

  return (
    <div>
      <div className="text-primaryBlue text-left font-tajawalMedium pb-4 pt-7 pl-[5px]">
        {handelRoute(location)}
      </div>
      <TitleComponent
        text={
          handelRoute(location) === "Create Visit"
            ? "Visit"
            : handelRoute(location)!
        }
      />
    </div>
  );
}
