import { useCallback, useEffect, useState } from "react";
import BreadcrumbComponent from "src/components/user/Breadcrumb-component";
import UserComponents from "src/components/user/user-components";
import { handleRequestError } from "src/core/helpers/error.helper";
import { getLocalStorage } from "src/core/stores/localStorage";
import { RegisterUserModel } from "src/core/types/user.type";
import { getUserInfoService } from "src/services/user-service";
export default function HeaderContainer() {
  const [data, setData] = useState<RegisterUserModel>();
  const token = getLocalStorage("token");
  const email = getLocalStorage("email");
  const [loadin, setLoading] = useState(false);

  const fetchReadData = useCallback(async () => {
    try {
      setLoading(true);
      const UserData: RegisterUserModel = await getUserInfoService(
        email as string,
        token || "",
      );
      setData(UserData);
    } catch (error) {
      handleRequestError(error);
    } finally {
      setLoading(false);
    }
  }, []);
  
  useEffect(() => {
    fetchReadData();
  }, [fetchReadData]);

  if (loadin) {
    return <></>;
  }
  return (
    <div className="flex justify-between">
      <BreadcrumbComponent />
      <UserComponents
        userId={
          typeof data?.is_superuser === "string" ? data.is_superuser : "Admin"
        }
        userName={data?.username!}
      />
    </div>
  );
}
