import React from "react";

interface card_content {
  name: string;
  city: string;
  credit: number;
}

function Company_info_card(menu: card_content) {
  return (
    <div
      className={`flex shadow-md rounded-lg items-center justify-center bg-white p-6`}
    >
      <div className="grid grid-cols-2 items-end justify-between">
        <p className="font-tajawalBold text-lg text-left">Name:</p>
        <p className="text-gray30 font-tajawalMedium text-right">{menu.name}</p>
        <p className="font-tajawalBold text-lg  text-left">City:</p>
        <p className="text-gray30  font-tajawalMedium text-right">
          {menu.city}
        </p>
        <p className="font-tajawalBold text-lg text-left">Remaining credit:</p>
        <p className="text-gray30 font-tajawalMedium text-right">{`${menu.credit}`}</p>
      </div>
    </div>
  );
}

export default Company_info_card;
