import React, { useCallback, useEffect, useState } from "react";
import Company_info_card from "src/containers/user/visit-container/company-info-card"; //"./src/containers/user/visit-container/company-info-card.tsx"
import { handleRequestError } from "src/core/helpers/error.helper";
import { getLocalStorage } from "src/core/stores/localStorage";
import { UpdateCenterModel } from "src/core/types/center.type";
import { RegisterUserModel } from "src/core/types/user.type";
import { getAllCentersInfo } from "src/services/center-service";
import { getUserInfoService } from "src/services/user-service";

export default function DashboardPage() {
  const [centers, setCenters] = useState<UpdateCenterModel>();
  const token = getLocalStorage("token");

  console.log(centers)

  const [centerId, setcenterId] = useState("");
  const email = getLocalStorage("email");
  const [loadin, setLoading] = useState(false);

  const fetchReadData = useCallback(async () => {
    try {
      setLoading(true);
      const UserData: RegisterUserModel = await getUserInfoService(
        email as string,
        token || ""
      );
      setcenterId(UserData?.assigned_centres);
    } catch (error) {
      handleRequestError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchReadData();
  }, [fetchReadData]);

  const fetchVisit = useCallback(async () => {
    try {
      const centersData: UpdateCenterModel = await getAllCentersInfo(
        token || "",
        centerId as string
      );

      setCenters(centersData);
    } catch (error) {
      handleRequestError(error);
    }
  }, [token, centerId]);

  useEffect(() => {
    if (centerId !== "") {
      fetchVisit();
    }
  }, [fetchVisit, centerId]);

  console.log(centerId);

  return (
    <div className="grid grid-cols-3 gap-5 mt-5">
      {centers &&
          <div>
            <Company_info_card
              name={centers.centre_name}
              city={centers.centre_city}
              credit={centers.credit}
            />
          </div>
}
    </div>
  );
}
