interface titleProps {
  text: string;
}
export default function TitleComponent(props: titleProps) {
  return (
    <div className="font-tajawalBold text-gray-700 text-4xl leading-[45px] text-left">
      {props.text}
    </div>
  );
}
