import React from "react";
import { FaUserCircle } from "react-icons/fa";

interface userProps {
  userName: string;
  userId: string;
}
export default function UserComponents({ userName, userId }: userProps) {
  return (
    <div className="flex gap-3 pt-7">
      <FaUserCircle className="w-8 h-8" />
      <div className="flex flex-col justify-start items-start gap-y-1 gap-x-3">
        <p className="text-neutral-900 font-tajawalRegular text-sm capitalize">
          {userName}
        </p>
        <div className="bg-midOrange rounded-lg px-[6px] leading-4 capitalize">
          <p className="text-neutral-900 font-tajawalRegular text-[10px] text-center  items-center leading-3 pt-[2px]">
            {userId}
          </p>
        </div>
      </div>
    </div>
  );
}
