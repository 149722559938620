import { baseURL, makeRequest } from "src/core/data-access/http.service";
import { HttpMethods } from "src/core/enums/httpMethods.enum";
import { LoginUserModel, RegisterUserModel } from "src/core/types/user.type";

export const postRegisterUserService = async (data: RegisterUserModel) => {
  return await makeRequest({
    url: `${baseURL}/register/`,
    method: HttpMethods.POST,
    data,
  });
};

export const postLoginUserService = async (data: LoginUserModel) => {
  return await makeRequest({
    url: `${baseURL}/login/`,
    method: HttpMethods.POST,
    data,
  });
};

export const getUserInfoService = (email: string, token: string) => {
  return makeRequest({
    url: `${baseURL}/users`,
    method: HttpMethods.GET,
    headers: {
      Authorization: `Token ${token}`,
    },
    params: { email: email },
  });
};

export const deleteUserService = () => {
  return makeRequest({
    url: `${baseURL}/del-users/`,
    method: HttpMethods.DELETE,
    params: {},
  });
};
